import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import Title from "../components/Title";
import Section from "../components/Section";
import styles from "./contact.module.css";
import breakpoints from "../style/Breakpoints";

class Contact extends React.Component {
  render() {
    const { shareInfo, title, text, image } = this.props.data.page;
    const { address, phone, email } = this.props.data.contact;
    const sizes = `(min-width: ${breakpoints.medium}) 68vw, 100vw`;
    image.childImageSharp.fluid.sizes = sizes;
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content="Lunsford Ridge Farm | Contact" />
          <meta
            property="og:url"
            content="https://www.lunsfordridgefarm.com/contact"
          />
        </Helmet>
        {shareInfo && shareInfo.image && shareInfo.description && (
          <Helmet>
            <meta name="description" content={shareInfo.description} />
            <meta property="og:description" content={shareInfo.description} />
            <meta
              property="og:image"
              content={`https://www.lunsfordridgefarm.com${shareInfo.image.childImageSharp.fixed.src}`}
            />
          </Helmet>
        )}
        <Section>
          <Title>{title}</Title>
          <div className={styles.contact}>
            <Img
              className={styles.contact__img}
              fluid={image.childImageSharp.fluid}
              fadeIn={false}
              alt="Lunsford Welcome"
            />
            <div className={styles.contact__textwrapper}>
              <p className={styles.contact__blurb}>{text}</p>
              <div className={styles.contact__line}>
                <span>PHONE</span>
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
              <div className={styles.contact__line}>
                <span>EMAIL</span>
                <a href={`mailto:${email}`}>{email}</a>
              </div>
              <div className={styles.contact__line}>
                <span>ADDRESS</span>
                <span>{address}</span>
              </div>
            </div>
          </div>
        </Section>
      </>
    );
  }
}

export default Contact;

export const pageQuery = graphql`
  query {
    ...ContactMetadataQuery
    page: pagesJson(pageName: { eq: "contact" }) {
      ...OpenGraphFragment
      title
      text
      image {
        childImageSharp {
          fluid(quality: 100, cropFocus: ATTENTION, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
